import Link from "next/link";
import { useRouter } from "next/router";
import { PropTypes } from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import Loader from "@/components/Loader";
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from "@/constants";
import { LeadFlowUtils } from "@/features/lead-flow";

import styles from "./ExitFlowConfirmation.module.scss";

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog
 */
const ExitFlowConfirmation = ({
  cancelButtonText,
  confirmButtonText,
  defaultExitPath,
  description,
  exitButtonText,
  title,
  skipConfirmation,
}) => {
  const dialogRef = useRef(null);
  const router = useRouter();
  const [hasBackdrop, setHasBackdrop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cancelOrCloseListener = () => {
    document.body.removeAttribute("inert");
    document.activeElement?.blur();
  };

  useEffect(() => {
    const currentDialogRef = dialogRef?.current;

    currentDialogRef?.addEventListener("cancel", cancelOrCloseListener);
    currentDialogRef?.addEventListener("close", cancelOrCloseListener);

    return () => {
      currentDialogRef?.removeEventListener("cancel", cancelOrCloseListener);
      currentDialogRef?.removeEventListener("close", cancelOrCloseListener);
    };
  }, []);

  const handleCancelClick = () => {
    document.body.removeAttribute("inert");

    if (dialogRef.current.close) {
      dialogRef.current.open && dialogRef.current.close();
    } else {
      dialogRef.current.removeAttribute("open");
      setHasBackdrop(false);
    }

    document.activeElement?.blur();
  };

  const handleConfirmClick = () => {
    const exitPath = sessionStorage?.getItem(
      SESSION_STORAGE_KEYS.JOIN_LEAD_FLOW_EXIT_PATH
    );

    document.body.removeAttribute("inert");
    setIsLoading(true);

    if (exitPath) {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.JOIN_LEAD_FLOW_EXIT_PATH);
      localStorage.removeItem(LOCAL_STORAGE_KEYS.CORP_JOIN_FLOW_DATA);
      LeadFlowUtils.clearStoredState();

      if (
        exitPath !== document?.URL &&
        !["/inquiry", "/join", "/membership"].some((path) =>
          exitPath.includes(window?.location?.host + path)
        )
      ) {
        router.push(exitPath);
        return;
      }
    }

    router.push(defaultExitPath);
  };

  const handleShowModalClick = (e) => {
    e && e.preventDefault();

    document.body.setAttribute("inert", "");

    if (skipConfirmation) {
      handleConfirmClick();
    } else {
      if (dialogRef.current.showModal) {
        !dialogRef.current.open && dialogRef.current.showModal();
      } else {
        dialogRef.current.setAttribute("open", "");
        setHasBackdrop(true);
      }

      document.activeElement?.blur();
    }
  };

  return (
    <>
      <Link href="/">
        <a
          className={styles.exitButton}
          onClick={handleShowModalClick}
          title={exitButtonText}
        >
          {exitButtonText}
        </a>
      </Link>

      {isLoading && <Loader className={styles.loader} color="light" />}

      <dialog className={styles.dialog} ref={dialogRef}>
        <header>
          <h2 className={styles.title}>{title}</h2>
        </header>

        {description && <p className={styles.description}>{description}</p>}

        <form className={styles.buttons} method="dialog">
          <button
            className={styles.cancelButton}
            name="Features:UserFlow:ExitFlowConfirmation - Cancel Button"
            onClick={handleCancelClick}
          >
            {cancelButtonText}
          </button>
          <button
            className={styles.confirmButton}
            name="Features:UserFlow:ExitFlowConfirmation - Confirm Button"
            onClick={handleConfirmClick}
          >
            {confirmButtonText}
          </button>
        </form>
      </dialog>

      {hasBackdrop && <span className={styles.backdrop} />}
    </>
  );
};

ExitFlowConfirmation.propTypes = {
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  defaultExitPath: PropTypes.string,
  description: PropTypes.string,
  exitButtonText: PropTypes.string,
  skipConfirmation: PropTypes.bool,
  title: PropTypes.string,
};

ExitFlowConfirmation.defaultProps = {
  cancelButtonText: "Stay",
  confirmButtonText: "Leave",
  defaultExitPath: "/memberbenefits",
  exitButtonText: "Exit",
  skipConfirmation: false,
  title: "Are you sure you want\nto leave?",
};

export default ExitFlowConfirmation;
