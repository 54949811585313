import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./AppointmentActions.module.scss";

const AppointmentActions = ({
  canSchedule = true,
  canSkip = true,
  ctaText,
  onSchedule,
  onSkip,
}) => {
  const newCtaText = canSkip && !canSchedule ? "Skip Appointment" : ctaText;
  const onSkipOrSchedule = canSkip && !canSchedule ? onSkip : onSchedule;

  return (
    <>
      <div
        className={classNames(
          styles.submitContainer,
          canSchedule && styles.submitContainerSticky
        )}
      >
        <div className={classNames(styles.cta)}>
          <button
            className={styles.ctaButton}
            name="LeadFlow:Steps:AppointmentActions - Schedule CTA"
            onClick={onSkipOrSchedule}
          >
            {newCtaText}
          </button>
        </div>
      </div>
    </>
  );
};

AppointmentActions.propTypes = {
  canSchedule: PropTypes.bool,
  canSkip: PropTypes.bool,
  ctaText: PropTypes.string,
  onSchedule: PropTypes.func,
  onSkip: PropTypes.func,
};

export default AppointmentActions;
