import PropTypes from "prop-types";
import React from "react";

import styles from "./TextWithRollingList.module.scss";

const TextWithRollingList = ({ endText, list = [], startText }) => {
  if (!list?.length > 0) {
    return null;
  }

  // duplicate 1st item on end to smooth loop...
  const paddedList = [...list, ...list.slice(0, 1)];

  const keyframes = [];

  for (let i = 0, listLength = paddedList.length; i < listLength; i++) {
    keyframes.push(
      `${((i + 1) / listLength) * 100}% { transform: translateY(-${i}em); }`
    );
  }

  return (
    <span data-is="TextWithRollingList">
      <style>{`
        @keyframes ${styles.itemAnimation} {
          ${keyframes.join("\n")}
        }
      `}</style>

      {startText}

      <ul className={styles.list}>
        {paddedList.map((item, index) => (
          <li
            className={styles.item}
            key={`${item}-${index}`}
            style={{ animationDuration: `${paddedList.length * 2}s` }}
          >
            {item}
          </li>
        ))}
      </ul>

      {endText}
    </span>
  );
};

TextWithRollingList.propTypes = {
  endText: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  startText: PropTypes.string,
};

export default TextWithRollingList;
