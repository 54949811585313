import Utilities from "./utilities";

//EQUIFIT ENDPOINTS
export const GET_PRE_QUESTIONNAIRE_URL = (source) =>
  `/v6/personal-training/equifit/pre-questionnaire?source=${source}`;
export const SUBMIT_QUESTIONNAIRE_URL = (source) =>
  `/v6/personal-training/pre-assessment-answer?source=${source}`;
export const GET_ALL_FACILITY_URL = () => `/v6/facilities/`;
export const REQEST_EQUIFIT_URL = () =>
  `/v6/personal-training/equifit/assessment`;
export const GET_SUMMARY_DATA_URL = (source) =>
  `/v6/personal-training/equifit/summary?source=${source}`;

//ACQ_BILLING ENDPOINTS
export const GET_BILLING_INFO_URL = () => `/v6.2/member/billing-information`;
export const UPDATE_BALANCE_URL = () => `/v6.2/member/billing/card/balance`;
export const UPDATE_CARD_URL = () => `/v6.2/member/billing/card`;
export const GET_ACCOUNT_INFO = () => `/v6/account/account-information`;

//DIGITAL SIGNATURE ENDPOINTS
export const GET_DIGITAL_SIGNATURE_URL = (memberEncryptedId) =>
  `/v6/acq/digitalsignature/agreement/${memberEncryptedId}?_=${Utilities.getCurrentTimeStamp()}`;
export const GET_HOTEL_DIGITAL_SIGNATURE = (id, timestamp, ssid, isHotel) =>
  isHotel
    ? `/v6/acq/digitalsignature/agreement/${id}/${timestamp}/${ssid}/${isHotel}`
    : `/v6/acq/digitalsignature/agreement/${id}/${timestamp}/${ssid}`;
export const CONFIRM_CONTRACT_URL = () =>
  `/v6/acq/digitalsignature/signcontract`;

//GIFT CARD ENDPOINTS
export const GET_CATALOG_URL = () =>
  `/v6/utility/giftcard/catalog?_=${Utilities.getCurrentTimeStamp()}`;
export const GET_PRODUCT_TOKEN_URL = () => `/v6/acq/payment/token`;

//TRIAL PASS ENDPOINTS
export const GET_TRIAL_PASS = (id, sourceSystemId) =>
  `/v6/acq/trialpass/${id}/${sourceSystemId}?_=${Utilities.getCurrentTimeStamp()}`;

//FORGOT USER ENDPOINTS
export const VERIFY_EMAIL_ADDRESS = () =>
  "/v6/account/authentication/verify-username";

//FORGOT PASSWORD ENDPOINTS
export const RECOVER_PASSWORD_FROM_EMAIL = () => `/v6/account/recover/email`;
export const GET_USER_DETAILS_FROM_TOKEN = (tokenId) =>
  `/v6/utility/token/${tokenId}`;
export const ACTIVATE_ACCOUNT_WITH_PASSWORD = () =>
  "/v6/account/authentication/activate-with-password";

//ACQ REGISTRATION ENDPOINT
export const VALIDATE_USER_URL = () => `/v6/account/registration/validateuser`;
export const SIGNUP_WITH_FACEBOOK_URL = () =>
  `/v6/registration/signupwithfacebook`;

export const SIGNUP_WITH_EMAIL_URL = () =>
  `/v6/account/registration/signupwithemail`;
export const DECODE_TOKEN_URL = (id) => `/v6/utility/token/${id}`;
export const ACTIVATE_EMAIL_URL = () => `/v6/account/user/activate`;
export const RESEND_EMAIL_URL = (id) => `/v6/account/verify-email/resend/${id}`;

// SPA UI
export const FACILITY_OUTAGE = () => "/v6/utility/spa/book4time/outage";
export const MEMBER_BOOKING = (facilityId) =>
  `/v6/utility/spa/member/booking-url/${facilityId}`;
export const NON_MEMBER_BOOKING = (facilityId) =>
  `/v6/utility/spa/booking-url/${facilityId}`;

//FEEDBACK
export const SUBMIT_FEEDBACK = () => "/v6/utility/feedback/club";

//REFERRALS
export const GET_MEMBER_DETAILS_FROM_REFERRAL_ID = (referralId) =>
  `/v6/acq/referral/info/${referralId}`;
export const CREATE_REFERRAL = () => "/v6/acq/referral/create";
export const UPDATE_REFERRAL = () => "/v6/acq/referral/update";
export const GET_FACILITY_BY_ID = (facilityId) =>
  `/v6/facilities/facility/${facilityId}`;
