import logger from "../../../utils/logger";
import ApiClient from "../client";

const MembershipPlans = {
  namespace: "membershipPlans",
  basePath: `/v6/acq/corp/plans`,

  async findCorporateMembershipPlansById(
    facilityId,
    companyEmail,
    corporateAccountId
  ) {
    const endpoint = `${this.basePath}/${facilityId}?email=${companyEmail}&corporateAccountId=${corporateAccountId}`;

    return ApiClient._get(endpoint).then((resp) => {
      if (!resp.data.success) {
        logger.warn(`API|MembershipPlans: no plans found `);
      }

      const {
        data: {
          result,
          clubName,
          region,
          promotionId,
          memberId,
          isPresale,
          facilityStatus,
          facilityOpenDate,
        },
      } = resp;

      return {
        result,
        clubName,
        region,
        promotionId,
        memberId,
        isPresale,
        facilityStatus,
        facilityOpenDate,
        corporateAgreementId: resp.data.corporateAgreementId,
      };
    });
  },
};

export default MembershipPlans;
