import { v4 as uuidv4 } from "uuid";

import { ACDL_JOIN_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/join-flow";
import {
  COUNTRIES,
  NEWSLETTER_DECLINE_ANSWER_ID,
  NEWSLETTER_SUBSCRIBE_ANSWER_ID,
} from "@/constants";
import { utils as userFlowUtils } from "@/features/user-flow";
import FormattingUtils from "@/utils/helpers/formatting";

import { fetchApi } from "./api-services";
import {
  CREATE_REFERRAL,
  GET_FACILITY_BY_ID,
  GET_MEMBER_DETAILS_FROM_REFERRAL_ID,
  UPDATE_REFERRAL,
} from "./api_config";

const Referrals = {
  getMemberDetails(referralId) {
    const endpoint = GET_MEMBER_DETAILS_FROM_REFERRAL_ID(referralId);
    return new Promise((resolve, reject) => {
      fetchApi(`${endpoint}`, "GET", true)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  createReferral({
    formState,
    formValues,
    receiveTextAlerts,
    setNewDataLayer,
    subscribeToNewsletter,
  }) {
    const facility =
      formState.scheduledFacility ||
      formState.selectedFacility ||
      formState.suggestedFacility;

    const countryCode =
      facility?.country || facility?.contactInformation?.country;
    const endpoint = CREATE_REFERRAL();
    const newsletter =
      countryCode !== COUNTRIES.UNITED_KINGDOM.abbreviation ||
      subscribeToNewsletter;
    const payload = {
      nameFirst: formValues["First Name"],
      nameLast: formValues["Last Name"],
      facilityKey: Number(formState?.selectedFacility?.facilityId),
      emailAddress: formValues["Email Address"],
      companyEmail: formValues["Company Email (optional)"],
      phoneNumber: formValues["Phone Number"],
      userLocation: formState?.selectedFacility?.city,
      transactionId: uuidv4(),
      friendId: "",
      outreachCode: userFlowUtils.modifyOutreachCodeForVwoCampaign(
        formState.outreachCode
      ),
      shareId: formState?.referrerDetails?.shareId,
      referringId: formState?.referrerDetails?.referringId,
      memberId: formState?.referrerDetails?.memberId,
      sendConfirmationEmail: true,
      receiveTextAlerts,
      answers: [
        {
          answerId: newsletter
            ? NEWSLETTER_SUBSCRIBE_ANSWER_ID
            : NEWSLETTER_DECLINE_ANSWER_ID,
          additionalInfo: "",
        },
      ],
    };

    return new Promise((resolve, reject) => {
      fetchApi(`${endpoint}`, "POST", true, payload)
        .then((response) => {
          setNewDataLayer(
            ACDL_JOIN_FLOW_TRACKING.joinFlowLeadSubmit(
              FormattingUtils.encode(formValues["Email Address"]),
              payload.transactionId,
              userFlowUtils.modifyOutreachCodeForVwoCampaign(
                formState.outreachCode
              )
            )
          );

          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  updateReferral(payload) {
    const endpoint = UPDATE_REFERRAL();
    return new Promise((resolve, reject) => {
      fetchApi(`${endpoint}`, "PUT", true, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  getFacilityById(facilityId) {
    const endpoint = GET_FACILITY_BY_ID(facilityId);
    return new Promise((resolve, reject) => {
      fetchApi(`${endpoint}`, "GET", true)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

export default Referrals;
