const addCurrencySymbol = (amount, country, isPricingLeadFlow) => {
  let currency = "";
  switch (country) {
    case "CA":
      currency = "CAD";
      break;
    case "UK":
      currency = "GBP";
      break;
    default:
      currency = "USD";
      break;
  }

  return amount.toLocaleString("en", {
    style: "currency",
    currency,
    ...(isPricingLeadFlow && { minimumFractionDigits: 0 }),
  });
};

export default addCurrencySymbol;
