import logger from "../../../utils/logger";
import ApiClient from "../client";

const LeadAppointments = {
  namespace: "leadappointments",
  basePath: "/v6/acq/leads/appointments",

  async findById(id) {
    const endpoint = `${this.basePath}?facilityId=${id}`;
    return ApiClient._get(endpoint).then((resp) => {
      if (resp.data.dateRangeWithTime.length === 0) {
        logger.warn(`API|Schedule: no dates found with id ${id}`);
        return null;
      }

      return resp.data;
    });
  },
};

export default LeadAppointments;
