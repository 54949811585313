import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useContext, useState } from "react";

import Eqxexclamationmark from "@/assets/eqx-exclamationmark.png";
import ContactConsentNotice from "@/components/ContactConsentNotice";
import { UserContext } from "@/context/User";
import { EqxForm, EqxTextInput } from "@/features/eqx-form";
import { LeadFlowConfig } from "@/features/lead-flow";
import { CorpJoinFormSchema } from "@/utils/helpers/validations";

import styles from "./CorpJoinForm.module.scss";

const CorpJoinForm = ({
  config,
  clubIsInUK,
  errorMessage,
  onSubmit,
  onSelectClubClick,
  selectedClub,
  setNewDataLayer,
}) => {
  const userContext = useContext(UserContext);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [receiveTextAlerts, setReceiveTextAlerts] = useState(false);
  const [subscribeToNewsletter, setSubscribeToNewsletter] = useState(false);
  const [contactFormIsValid, setContactFormIsValid] = useState();
  const [formErrorMessage, setFormErrorMessage] = useState();
  const showJoinAction = true;
  const showLeadAction = true;
  const userIsInUK =
    (userContext.userLocation &&
      userContext.userLocation.location &&
      userContext.userLocation.location.is_eu) ||
    clubIsInUK;

  const onSubmitError = (onSubmitErrorValue) => {
    setContactFormIsValid(!onSubmitErrorValue);
    setFormErrorMessage(onSubmitErrorValue);
  };

  const handleFormValidityChange = (isValid) => {
    setContactFormIsValid(isValid);
  };

  const handleSubmitForm = async (formValues) => {
    if (contactFormIsValid && selectedClub) {
      onSubmit({
        ...formValues,
        receiveTextAlerts,
        selectedClub,
        subscribeToNewsletter,
      });
    }
  };

  const handleUserOptInClick = () => {
    setReceiveTextAlerts(!receiveTextAlerts);
    if (!receiveTextAlerts) {
      //condition is the other way around, since this value represents the prev state
      setNewDataLayer({
        event: "mod-click",
        click: {
          name: "Notification selection",
          destination: "NA",
          location: "lead view | body",
        },
        module: {
          type: "box",
          name: "NotificationsSelected",
          position: "1:1",
        },
      });
    }
  };

  const showClubError = hasSubmitted && !selectedClub;

  return (
    <EqxForm
      className={styles.eqxForm}
      defaultValues={{
        "First Name": "",
        "Last Name": "",
        "Personal Email": "",
        "Phone Number": "",
        "Company Email": "",
      }}
      onSubmit={handleSubmitForm}
      onSubmitError={onSubmitError}
      onValidityChange={handleFormValidityChange}
      schema={CorpJoinFormSchema}
    >
      <div className={styles.firstName}>
        <EqxTextInput
          className={classNames(styles.formField)}
          label="First Name"
        />
        {formErrorMessage?.["First Name"] && (
          <span className={styles.errorText}>
            {formErrorMessage["First Name"]?.message}
          </span>
        )}
      </div>

      <div className={styles.lastName}>
        <EqxTextInput
          className={classNames(styles.formField)}
          label="Last Name"
        />
        {formErrorMessage?.["Last Name"] && (
          <span className={styles.errorText}>
            {formErrorMessage["Last Name"]?.message}
          </span>
        )}
      </div>

      <EqxTextInput
        className={styles.formField}
        label="Personal Email"
        type="email"
      />
      {formErrorMessage?.["Personal Email"] && (
        <span className={styles.errorText}>
          {formErrorMessage["Personal Email"]?.message}
        </span>
      )}

      <EqxTextInput
        className={styles.formField}
        label="Company Email"
        type="email"
      />
      {formErrorMessage?.["Company Email"] && (
        <span className={styles.errorText}>
          {formErrorMessage["Company Email"]?.message}
        </span>
      )}

      <EqxTextInput
        className={styles.formField}
        inputProps={{ maxLength: 16 }}
        label="Phone Number"
      />
      {formErrorMessage?.["Phone Number"] && (
        <span className={styles.errorText}>
          {formErrorMessage["Phone Number"]?.message}
        </span>
      )}

      <div
        className={styles.clubSelectContainer}
        data-blank={!selectedClub}
        onClick={() => {
          onSelectClubClick(true);
        }}
      >
        <span className={styles.clubSelectLabel}>Select a club</span>
        <div
          className={classNames(
            styles.clubNameText,
            showClubError ? styles.clubNameTextError : ""
          )}
        >
          {selectedClub?.webName}
        </div>
        {showClubError && (
          <span className={styles.errorTextClub}>Club is required.</span>
        )}
      </div>

      {errorMessage && <p className={styles.apiErrorMessage}>{errorMessage}</p>}

      <ContactConsentNotice className={styles.agreementText} />

      {hasSubmitted && (!contactFormIsValid || showClubError) && (
        <div className={styles.buttonMessage}>
          <span>
            <img
              alt="Equinox"
              src={`${Eqxexclamationmark}`}
              style={{ maxHeight: "20px", margin: "10px" }}
            />
          </span>
          <span>{config?.CompleteTheInfo}</span>
        </div>
      )}

      {(showJoinAction || showLeadAction) && (
        <span className={styles.ctaButtonGroup}>
          {showLeadAction && (
            <button
              className={styles.ctaButton}
              data-track="lead-flow:contact-info:lead-cta"
              name="CorpJoinFlow:CorpJoinForm - Get Started"
              onClick={() => {
                setHasSubmitted(true);
              }}
              type="submit"
            >
              Get Started
            </button>
          )}
        </span>
      )}

      {userIsInUK && (
        <>
          <FormControlLabel
            className={styles.checkboxLabel}
            control={
              <Checkbox
                checked={subscribeToNewsletter}
                className={styles.checkboxField}
              />
            }
            label={LeadFlowConfig?.textContent?.ukOptInCBText}
            labelPlacement="end"
            onClick={() => {
              setSubscribeToNewsletter(!subscribeToNewsletter);
            }}
          />
          <small className={styles.ukLegalText}>
            {LeadFlowConfig?.textContent?.ukOptInText}
          </small>
        </>
      )}

      <>
        <FormControlLabel
          className={styles.checkboxLabel}
          control={
            <Checkbox
              checked={receiveTextAlerts}
              className={styles.checkboxField}
            />
          }
          label={LeadFlowConfig?.textContent?.optInText}
          labelPlacement="end"
          onClick={handleUserOptInClick}
        />
        <small className={styles.legalText}>
          {LeadFlowConfig?.textContent?.termsText}
        </small>
      </>

      {userIsInUK && (
        <small className={styles.legal}>
          {LeadFlowConfig?.textContent?.ukLegalSubText}
        </small>
      )}
    </EqxForm>
  );
};

CorpJoinForm.propTypes = {
  clubIsInUK: PropTypes.bool,
  config: PropTypes.object,
  errorMessage: PropTypes.string,
  onSelectClubClick: PropTypes.func,
  onSubmit: PropTypes.func,
  selectedClub: PropTypes.object,
  setNewDataLayer: PropTypes.func,
};

export default CorpJoinForm;
