import PropTypes from "prop-types";
import React, { useState } from "react";

import ErrorIcon from "@/assets/icons/error.svg";

import styles from "./ChooseMembershipPlan.module.scss";
import MembershipPlans from "./MembershipPlans";

const ChooseMembershipPlan = ({
  companyName,
  onChangeClub,
  onShowScheduleVisit,
  onSubmit,
  plans,
  selectedFacility,
}) => {
  const [selectedPlanDetails, setSelectedPlanDetails] = useState();
  const [clickedWithoutSelection, setClickedWithoutSelection] = useState(false);

  const handleFormSubmit = async (e) => {
    e && e.preventDefault();

    if (!selectedPlanDetails) {
      setClickedWithoutSelection(true);
      return;
    }

    onSubmit(selectedPlanDetails);
  };

  const handleClubChange = async () => {
    onChangeClub();
    setSelectedPlanDetails(null);
  };

  const selectedMembershipCard = (selectedPlan) => {
    setClickedWithoutSelection(false);
    setSelectedPlanDetails(selectedPlan);
  };

  return (
    <article className={styles.chooseMembershipPlan}>
      {plans?.length > 0 && (
        <p className={styles.lede}>
          Enjoy unlimited in-club and digital classes, access to EQX+ app, a
          complimentary fitness assessment and more.
        </p>
      )}

      {!plans?.length && (
        <p className={styles.lede}>
          Your employer does not offer an exclusive membership with this club.
          Please choose another location.
        </p>
      )}

      <form className={styles.plansForm} onSubmit={handleFormSubmit}>
        <div
          className={styles.plansContainer}
          data-sticky={!!selectedPlanDetails}
        >
          <MembershipPlans
            companyName={companyName}
            currentSelectedPlan={selectedPlanDetails}
            key="plans"
            onChangeClub={handleClubChange}
            plans={plans}
            selectedClubDetails={selectedFacility}
            selectedMembershipCard={selectedMembershipCard}
          />

          <span className="">
            <p className={styles.visitText}>
              Want to visit the club first?
              <a
                className={styles.scheduleText}
                onClick={onShowScheduleVisit}
                role="button"
              >
                Schedule a visit
              </a>
            </p>
          </span>
        </div>

        {clickedWithoutSelection && (
          <div className={styles.unselectedTimeContainer}>
            <span className={styles.errorIconSvg}>
              <ErrorIcon />
            </span>
            <span className={styles.errorIconText}>
              Select a membership type above to join.
            </span>
          </div>
        )}

        <div className={styles.ctaWrapper} data-sticky={!!selectedPlanDetails}>
          <button
            className={styles.purchaseCta}
            data-sticky={!!selectedPlanDetails}
            name="CorpJoinFlow:ChooseMembershipPlan - Purchase Membership"
            type="submit"
          >
            {"Purchase membership"}
          </button>
        </div>
      </form>
    </article>
  );
};

ChooseMembershipPlan.propTypes = {
  companyName: PropTypes.string,
  onChangeClub: PropTypes.func,
  onShowScheduleVisit: PropTypes.func,
  onSubmit: PropTypes.func,
  plans: PropTypes.array,
  selectedFacility: PropTypes.object,
};

export default ChooseMembershipPlan;
