import Utilities from "@/api/equifit/utilities";
import { COUNTRIES, LOCAL_STORAGE_KEYS } from "@/constants";
import dynamicContentReplacer from "@/utils/helpers/dynamic_content_replacer";
import { createItem, getItem, removeItem } from "@/utils/helpers/localStorage";

import { STEPS } from "./constants";

/**
 *
 */
export const updateStoredState = (leadFlowState) => {
  if (Utilities.isLocalStorageAvailable()) {
    createItem(LOCAL_STORAGE_KEYS.CORP_JOIN_FLOW_DATA, leadFlowState);
  }
};

/**
 *
 */
export const clearStoredState = () => {
  if (Utilities.isLocalStorageAvailable()) {
    removeItem(LOCAL_STORAGE_KEYS.CORP_JOIN_FLOW_DATA);
    removeItem(LOCAL_STORAGE_KEYS.CORP_JOIN_FLOW_PLANS);
  }
};

/**
 *
 */
export const getStoredState = () => {
  let leadFlowState;

  if (Utilities.isLocalStorageAvailable()) {
    leadFlowState = getItem(LOCAL_STORAGE_KEYS.CORP_JOIN_FLOW_DATA);

    if (leadFlowState) {
      const { createdDate } = leadFlowState;

      if (!createdDate) {
        clearStoredState();
        return null;
      }

      const now = new Date().getTime();
      const msBetweenDates = Math.abs(createdDate - now);
      const isStaleSession = msBetweenDates > 1800000; // 30 minutes

      if (isStaleSession) {
        clearStoredState();
        return null;
      }

      return leadFlowState;
    }
  }

  if (!leadFlowState) {
    return {
      step: STEPS.contactInformation,
    };
  }
};

/**
 *
 */
export const derivePayloadFromState = (formState) => {
  const facility =
    formState.scheduledFacility ||
    formState.selectedFacility ||
    formState.suggestedFacility;

  const payload = {
    Answers: formState.answers,
    companyEmail: formState.contactInput["Company Email"],
    CTALabel: formState.ctaLabel,
    EmailAddress: formState.contactInput["Personal Email"],
    FacilityId: facility.facilityId,
    FirstName: formState.contactInput["First Name"],
    IsAbandonedLead: true,
    LastName: formState.contactInput["Last Name"],
    meetingPurpose: formState.meetingPurpose,
    newsletter:
      facility?.country !== COUNTRIES.UNITED_KINGDOM.abbreviation ||
      formState.contactInput.subscribeToNewsletter,
    OutreachCode: formState.outreachCode,
    PhoneNumber: formState.contactInput["Phone Number"],
    PreferredCommunicationMethod: formState.preferedCommunicationMethod,
    PreferredTimeOfDay: formState.preferredTimeOfDay,
    receiveTextAlerts: formState?.receiveTextAlerts,
    ScheduledVisitTime: formState.scheduledVisitTime,
    SourceUrl: formState.sourceUrl,
    TransactionId: formState.leadTransactionId,
    UserLocation: facility.facilityId,
  };

  return payload;
};

/**
 *
 */
export const deriveEmailPayloadFromState = (formState) => {
  const facility =
    formState.scheduledFacility ||
    formState.selectedFacility ||
    formState.suggestedFacility;

  const payload = {
    firstName: formState.contactInput["First Name"],
    lastName: formState.contactInput["Last Name"],
    personalEmail: formState.contactInput["Personal Email"],
    corporateEmail: formState.contactInput["Company Email"],
    phoneNumber: formState.contactInput["Phone Number"],
    facilityId: facility.facilityId,
  };

  return payload;
};

/**
 *
 */
export const replaceDynamicContentOfPlan = (value, facility) => {
  if (!facility) {
    return value;
  }

  const { webName, region } = facility;
  const replacerConfig = {
    "{{CLUB_NAME}}": webName,
    "{{REGION}}": region,
  };

  return dynamicContentReplacer(value, replacerConfig);
};

/**
 *
 */
export const setCorpJoinFlowPlans = (plans) => {
  if (Utilities.isLocalStorageAvailable()) {
    createItem(LOCAL_STORAGE_KEYS.CORP_JOIN_FLOW_PLANS, plans);
  }
};
