import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import CloseX from "@/assets/icons/close.svg";
import ChangeClubPanel from "@/components/ChangeClubPanel";

import styles from "./ClubSelectorPanel.module.scss";

/**
 *
 */
const ClubSelectorPanel = ({ isOpen = false, onClose, onChangeClub }) => {
  const dialogRef = useRef(null);
  const [hasBackdrop, setHasBackdrop] = useState(false);

  const handleChangeClub = async (club) => {
    onChangeClub(club);
  };

  const handleCloseClick = () => {
    onClose();
  };

  useEffect(() => {
    const cancelListener = () => {
      document.body.removeAttribute("inert");
      onClose();
    };
    const currentDialogRef = dialogRef?.current;

    currentDialogRef?.addEventListener("cancel", cancelListener);

    return () => {
      currentDialogRef?.removeEventListener("cancel", cancelListener);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      if (dialogRef.current.showModal) {
        !dialogRef.current.open && dialogRef.current.showModal();
      } else {
        dialogRef.current.setAttribute("open", "");
        setHasBackdrop(true);
      }

      document.body.setAttribute("inert", "");
    } else {
      if (dialogRef.current.close) {
        dialogRef.current.open && dialogRef.current.close();
      } else {
        dialogRef.current.removeAttribute("open");
        setHasBackdrop(false);
      }

      document.body.removeAttribute("inert");
    }
  }, [isOpen]);

  return (
    <>
      <dialog className={styles.clubSelector} ref={dialogRef}>
        <div className={styles.mainContent}>
          <ChangeClubPanel isOpen={isOpen} onChangeClub={handleChangeClub} />

          <button
            className={styles.closeBtn}
            name="ClubSelectorPanel - Close"
            onClick={handleCloseClick}
          >
            <CloseX />
          </button>
        </div>
      </dialog>

      {hasBackdrop && <span className={styles.backdrop} />}
    </>
  );
};

ClubSelectorPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onChangeClub: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ClubSelectorPanel;
