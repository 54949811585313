import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import CaretDown from "@/assets/icons/caret-down.svg";
import ResponsiveImage from "@/components/ResponsiveImage";
import { IMAGE_ASSETS_CDN_PATH } from "@/constants";
import { renderMarkdown } from "@/utils/helpers/markdown";

import styles from "./Headline.module.scss";

const Headline = ({
  className,
  eyebrow,
  finePrint,
  photo,
  references = [],
  showLogo = true,
  subcopy,
  title,
}) => {
  return (
    <header
      className={classNames(styles.headline, className)}
      data-has-selling-points={
        references?.[0]?.fields?.config?.type === "selling-points"
      }
      data-is="user-flow:Headline"
    >
      {photo?.fields && (
        <ResponsiveImage
          {...photo.fields}
          altText={photo.fields.altText || subcopy || title}
          className={styles.photo}
          title={photo.fields.title || title}
        />
      )}

      <div className={styles.text}>
        {showLogo && (
          <img
            alt="Equinox wordmark"
            className={styles.eqxLogo}
            src={`${IMAGE_ASSETS_CDN_PATH}/equinox-logo.svg`}
            title="Equinox"
          />
        )}

        {eyebrow && <small className={styles.eyebrow}>{eyebrow}</small>}

        {title && (
          <h1 className={styles.title}>
            {typeof title === "string" ? renderMarkdown(title) : title}
          </h1>
        )}

        {subcopy && (
          <div className={styles.subcopy}>
            {renderMarkdown(subcopy, { paragraphs: true })}
          </div>
        )}

        {finePrint && (
          <small
            className={styles.finePrint}
            dangerouslySetInnerHTML={{
              __html: finePrint,
            }}
          />
        )}

        {references?.[0]?.fields?.config?.type === "selling-points" && (
          <div className={styles.sellingPoints}>
            {references[0].fields.items?.map((item, index) => (
              <details
                className={styles.sellingPoint}
                key={item.fields?.key}
                name="selling-points"
                open={index === 0}
              >
                <summary className={styles.sellingPointTitle}>
                  {item.fields?.key}{" "}
                  <CaretDown alt="Classes" className={styles.caret} />
                </summary>
                <p className={styles.sellingPointDescription}>
                  {item.fields?.value}
                </p>
              </details>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

Headline.propTypes = {
  className: PropTypes.string,
  eyebrow: PropTypes.string,
  finePrint: PropTypes.string,
  photo: PropTypes.object,
  references: PropTypes.array,
  showLogo: PropTypes.bool,
  subcopy: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Headline;
