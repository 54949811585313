import classNames from "classnames";
import { format as formatDate, isToday, isTomorrow } from "date-fns";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { DATETIME } from "@/constants";

import styles from "./AppointmentSelector.module.scss";

/**
 *
 */
export const formatDisplayDate = (dateString) => {
  const dateObject = new Date(`${dateString}${DATETIME.UTC_MIDDAY}`);

  if (isToday(dateObject)) {
    return `Today, ${formatDate(dateObject, "MMMMMM d")}`;
  }

  if (isTomorrow(dateObject)) {
    return `Tomorrow, ${formatDate(dateObject, "MMMMMM d")}`;
  }

  return formatDate(dateObject, "eeee, LLLL d");
};

/**
 *
 */
export const formatDisplayTime = (isoDateTimeString) => {
  const dateObject = new Date(isoDateTimeString);
  return formatDate(dateObject, "h:mm a");
};

/**
 *
 */
const AppointmentSelector = ({
  appointments = [],
  className,
  onSelection,
  selectedAppointment,
}) => {
  useEffect(() => {
    document.querySelector('[aria-current="true"]')?.scrollIntoView({
      block: "nearest",
      inline: "start",
    });
  }, []);

  return (
    <ol
      className={classNames(className, styles.appointmentSelector)}
      data-is="user-flow:AppointmentSelector"
    >
      {appointments?.map(({ date, timeSlots, unavailableTimeSlots }) => {
        return (
          <li className={styles.appointmentsBlock} key={date}>
            <h4 className={styles.appointmentsDate}>
              {formatDisplayDate(date)}
            </h4>

            <ol className={styles.appointments}>
              {[...timeSlots, ...unavailableTimeSlots]
                .sort()
                .map((timeSlot) => {
                  const isoDateTimeString = `${date}T${timeSlot}`;

                  return (
                    <li className={styles.appointment} key={isoDateTimeString}>
                      <button
                        aria-current={selectedAppointment === isoDateTimeString}
                        disabled={unavailableTimeSlots.includes(timeSlot)}
                        name="Features:UserFlow:AppointmentSelector - Selection"
                        onClick={() =>
                          onSelection && onSelection(isoDateTimeString)
                        }
                      >
                        {formatDisplayTime(isoDateTimeString)}
                      </button>
                    </li>
                  );
                })}
            </ol>
          </li>
        );
      })}
    </ol>
  );
};

AppointmentSelector.propTypes = {
  appointments: PropTypes.array,
  className: PropTypes.string,
  onSelection: PropTypes.func,
  selectedAppointment: PropTypes.string,
};

export default AppointmentSelector;
