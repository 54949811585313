export const getCookieValue = (cookieName) =>
  document &&
  (
    document.cookie.split("; ").find((c) => c.split("=")[0] === cookieName) ||
    ""
  ).split("=")[1];

export const getActualURL = (callback) => {
  if (callback) {
    return callback(document.location.href);
  }
  return document.location.href;
};
