import PropTypes from "prop-types";
import React from "react";

import { ChangeClubButton } from "@/features/join-flow";
import addCurrencySymbol from "@/utils/helpers/addCurrencySymbol";

import styles from "./MembershipPlans.module.scss";

const MembershipPlans = ({
  companyName,
  currentSelectedPlan,
  onChangeClub,
  plans,
  selectedClubDetails,
  selectedMembershipCard,
}) => {
  const onPlanClick = (event, plan) => {
    event.stopPropagation();
    selectedMembershipCard(plan);
  };

  const renderDynamicPlanMessage = (plan) => {
    const regularMonthlyFee = plan?.cost?.regularMonthlyFee || 0;
    const monthlyFee = plan?.cost?.monthlyFee || 0;
    const totalDues = plan?.cost?.initiation?.totalDues || 0;
    const promotionalSavings = plan?.cost?.promotionalSavings || 0;
    const showReimbursement =
      plan?.cost?.isReimbursementAvailable && regularMonthlyFee === monthlyFee;

    if (showReimbursement) {
      return (
        <small className={styles.commitmentTerm}>
          Reimbursement available. Check with {companyName}.
        </small>
      );
    } else if (regularMonthlyFee - monthlyFee >= 30) {
      return (
        <small className={styles.commitmentTerm}>
          Normally{" "}
          {addCurrencySymbol(
            regularMonthlyFee,
            selectedClubDetails.country,
            true
          )}
          .
        </small>
      );
    } else if (regularMonthlyFee - monthlyFee < 30) {
      return (
        <small className={styles.commitmentTerm}>
          Including{" "}
          {addCurrencySymbol(
            totalDues - promotionalSavings,
            selectedClubDetails.country,
            true
          )}{" "}
          initiation. Normally{" "}
          {addCurrencySymbol(500, selectedClubDetails.country, true)}.
        </small>
      );
    }
  };

  if (plans?.length > 0) {
    return (
      <ul className={styles.membershipPlans}>
        {plans.map((plan, index) => {
          const { id, planType, title, cost } = plan;
          return (
            <li
              aria-checked={currentSelectedPlan?.id === id}
              className={styles.plan}
              id={`plan-${id}`}
              key={id}
              onClick={(e) => onPlanClick(e, plan)}
              role="menuitemcheckbox"
            >
              <small className={styles.planType}>{planType}</small>

              <label className={styles.planName}>
                <input
                  checked={currentSelectedPlan?.id === id}
                  data-adobe={title}
                  id={id}
                  name="plan"
                  onChange={(e) => onPlanClick(e, plan)}
                  type="radio"
                  value={id}
                />
                {title
                  .replace("{{CLUB_NAME}}", selectedClubDetails?.webName)
                  .replace("{{REGION}}", selectedClubDetails?.region)}
              </label>

              {index === 0 && (
                <ChangeClubButton
                  className={styles.changeClubButton}
                  handleClick={onChangeClub}
                />
              )}

              <div className={styles.planCost}>
                <strong className={styles.planCostAmount}>
                  {addCurrencySymbol(
                    cost?.monthlyFee,
                    selectedClubDetails.country
                  )}
                </strong>
                <span className={styles.planCostTerm}>Monthly</span>
              </div>

              {renderDynamicPlanMessage(plan)}
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <div className={styles.membershipPlans}>
      <div className={styles.noPlans}>
        <ChangeClubButton
          className={styles.changeClubButton}
          handleClick={onChangeClub}
        >
          Select a club to continue
        </ChangeClubButton>
      </div>
    </div>
  );
};

MembershipPlans.propTypes = {
  companyName: PropTypes.string,
  currentSelectedPlan: PropTypes.object,
  onChangeClub: PropTypes.func.isRequired,
  plans: PropTypes.array,
  selectedClubDetails: PropTypes.object,
  selectedMembershipCard: PropTypes.func,
};

export default MembershipPlans;
