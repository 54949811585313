import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { ExitFlowConfirmation, Headline } from "@/features/user-flow";

import PersonalInfo from "../PersonalInfo";
import styles from "./PersonalDetails.module.scss";

const PersonalDetails = ({
  data,
  defaultUserInfo,
  joinAbandoned,
  selectedClubDetails,
  selectedPlan,
  switchToStep,
}) => {
  const { headlineComponent, personalDetails } = data.fields;

  useEffect(() => {
    if (!selectedPlan.planId) {
      switchToStep(1);
    }
  }, [selectedPlan]); // eslint-disable-line react-hooks/exhaustive-deps -- WEB-2321

  return (
    <section className={styles.personalDetails}>
      <ExitFlowConfirmation />

      <Headline
        className={styles.headline}
        eyebrow="Step 2/3"
        finePrint={headlineComponent?.fields.finePrint}
        photo={headlineComponent?.fields.image}
        subcopy={headlineComponent?.fields.subcopy}
        title={headlineComponent?.fields.title}
      />

      <article className={styles.personalInfoWrapper}>
        <PersonalInfo
          country={selectedClubDetails.country}
          defaultUserInfo={defaultUserInfo}
          facilityId={selectedClubDetails.facilityId}
          handleJoinSubmit={joinAbandoned}
          joinOutreachCode={personalDetails.fields.joinMarketingCode}
          outreachCode={personalDetails.fields.marketingCode}
        />
      </article>
    </section>
  );
};

PersonalDetails.propTypes = {
  data: PropTypes.object.isRequired,
  defaultUserInfo: PropTypes.object,
  joinAbandoned: PropTypes.func.isRequired,
  selectedClubDetails: PropTypes.object.isRequired,
  selectedPlan: PropTypes.object.isRequired,
  switchToStep: PropTypes.func.isRequired,
};

export default PersonalDetails;
