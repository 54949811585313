import { Loader } from "@googlemaps/js-api-loader";
import PropTypes from "prop-types";
import React from "react";

import DeleteIcon from "@/assets/icons/delete-circle.svg";
import SearchIcon from "@/assets/icons/search.svg";

import styles from "./GoogleSearch.module.scss";

/**
 * https://developers.google.com/maps/documentation/javascript/place-autocomplete
 */
const componentRestrictions = {
  country: ["us", "ca", "uk"],
};

const fields = ["address_components", "formatted_address", "geometry", "types"];

const types = [
  "street_number",
  "route",
  "locality",
  "administrative_area_level_1",
  "postal_code",
];

class GoogleSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      place: "",
      userInput: "",
    };
    this.autocompleteInput = null;
    this.autoComplete = null;
    this.googleApi = null;
    this.hasSelectedOnce = false;
  }

  setInputRef = (input) => {
    this.props.setRef && this.props.setRef(input);
    this.autocompleteInput = input;
  };

  clearInput = () => {
    if (this.autocompleteInput.value === "") {
      return;
    }
    this.props.clearSearchInput();
    this.autocompleteInput.value = "";
    this.setState({ error: false, place: "" });
  };

  getFacilityByRegion = (region) => {
    const { handleInputSelect } = this.props;
    const { place, userInput } = this.state;
    handleInputSelect(null, null, region, userInput, place);
  };

  componentDidMount() {
    const loader = new Loader({
      apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      version: "weekly",
    });

    loader
      .load()
      .then(async (googleApi) => {
        this.googleApi = googleApi;
        const { LatLngBounds } = await googleApi.maps.importLibrary("core");
        const { Autocomplete } = await googleApi.maps.importLibrary("places");

        /**
         * Good to know:
         * By default, when a user selects a place, SearchBox returns all of the available data fields
         * for the selected place, and you will be billed accordingly. There is no way to constrain
         * SearchBox requests to only return specific fields. To keep from requesting (and paying for)
         * data that you don't need, use the Autocomplete widget instead.
         */

        this.autoComplete = new Autocomplete(this.autocompleteInput, {
          bounds: new LatLngBounds(),
          componentRestrictions,
          fields,
          types,
        });

        this.autoComplete.addListener("place_changed", this.handlePlaceChanged);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetTimestamp !== this.props.resetTimestamp) {
      this.autocompleteInput.value = "";
      this.setState({
        error: false,
        place: "",
        userInput: "",
      });
    }
  }

  componentWillUnmount() {
    const pacContainer = document.querySelector(".pac-container");
    if (pacContainer) {
      pacContainer.remove();
    }
    this.googleApi?.maps.event.clearInstanceListeners(this.autoComplete);
  }

  triggerDownArrow = () => {
    const downArrowEvent = new KeyboardEvent("keydown", {
      bubbles: true,
      cancelable: true,
      keyCode: 40,
      which: 40,
      key: "ArrowDown",
    });
    this.autocompleteInput.dispatchEvent(downArrowEvent);

    const timeoutId = setTimeout(() => {
      const enterEvent = new KeyboardEvent("keydown", {
        bubbles: true,
        cancelable: true,
        keyCode: 13,
        which: 13,
        key: "Enter",
      });
      this.autocompleteInput.dispatchEvent(enterEvent);
      this.setState({ hasSelectedOnce: false });
      clearTimeout(timeoutId);
    }, 100);
  };

  // Get the first place based on input given on enter key press
  handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && !this.state.hasSelectedOnce) {
      this.triggerDownArrow();
      this.setState({ hasSelectedOnce: true });
    }
  };

  handlePlaceChanged = () => {
    this.setState({ error: false });

    const { setSearchError, handleInputSelect } = this.props;
    setSearchError && setSearchError(false);

    const place = this.autoComplete.getPlace();

    if (place.length < 1) {
      setSearchError && setSearchError(true, this.state.place);
      this.setState({ error: true });
    } else if (
      place.types &&
      place.types[0] === "administrative_area_level_1"
    ) {
      this.getFacilityByRegion({
        region: place.address_components.slice(-2)[0].long_name,
        subRegion: "",
      });
    } else if (place.types && place.types[0] === "colloquial_area") {
      this.getFacilityByRegion({
        region: place.address_components.slice(-2)[0].long_name,
        subRegion: place.address_components.slice(-3)[0].long_name,
      });
    } else if (place?.geometry?.location) {
      const lat = place.geometry.location.lat();
      const long = place.geometry.location.lng();

      handleInputSelect(
        lat,
        long,
        undefined,
        this.state.userInput,
        place.formatted_address
      );
    }

    this.setState({ place: place?.formatted_address || "" });
  };

  render() {
    const { place } = this.state;

    return (
      <React.Fragment>
        <div className={styles.search}>
          <input
            aria-label="Search Text"
            autoComplete="off"
            className="location-search-input"
            name="autoSearchString"
            onChange={(e) =>
              this.setState({
                place: e.currentTarget.value,
                userInput: e.currentTarget.value,
              })
            }
            onKeyDown={(e) => this.handleEnterKeyPress(e)}
            placeholder="Search For A Location"
            ref={this.setInputRef}
            type="text"
            value={place}
          />
          <SearchIcon className={styles.searchIcon} />
          <DeleteIcon className={styles.deleteIcon} onClick={this.clearInput} />
        </div>
      </React.Fragment>
    );
  }
}

GoogleSearch.propTypes = {
  clearSearchInput: PropTypes.func.isRequired,
  handleInputSelect: PropTypes.func.isRequired,
  resetTimestamp: PropTypes.number,
  setRef: PropTypes.func,
  setSearchError: PropTypes.func,
};

export default GoogleSearch;
