import Utilities from "@/utils/helpers/formatting";

module.exports = {
  navigationBrandImages: [
    {
      mediaQuery: "default",
      params: { w: 700, h: 135, q: 60, fit: "thumb" },
    },
  ],
  appContentDeviceDisplay: [
    {
      mediaQuery: "default",
      params: { w: 380 },
    },
  ],
  heroBackgroundImage: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
    },
  ],
  heroBackgroundImageMobile: [
    {
      mediaQuery: "default",
      params: { w: 800 },
    },
  ],
  galleryDesktop: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
    },
  ],
  galleryMobile: [
    {
      mediaQuery: "default",
      params: { w: 768 },
    },
  ],
  homeHeroDesktop: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
      q: 45,
    },
  ],
  homeHeroMobile: [
    {
      mediaQuery: "default",
      params: { w: 768 },
    },
  ],
  brandHeroMobile: [
    {
      mediaQuery: "default",
      params: { h: 423 },
    },
  ],
  breakerDesktop: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
    },
  ],
  breakerMobile: [
    {
      mediaQuery: "default",
      params: { w: 768 },
    },
  ],
  fullScreenBackgroundDesktop: () => {
    const settings = {
      fl: "progressive",
      fm: "jpg",
      q: 55,
      w: 2200,
    };
    return Utilities.objectToQueryString(settings);
  },
  fullScreenBackgroundMobile: () => {
    const settings = {
      fl: "progressive",
      fm: "jpg",
      q: 45,
      w: 768,
    };
    return Utilities.objectToQueryString(settings);
  },
  editorialBackgroundPortrait: () => {
    const settings = {
      fl: "progressive",
      fm: "jpg",
      q: 75,
      w: 900,
    };
    return Utilities.objectToQueryString(settings);
  },
  singleImageDesktop: [
    {
      mediaQuery: "default",
      params: { w: 1900, fit: "fill", q: 70 },
    },
  ],
  singleImageMobile: [
    {
      mediaQuery: "default",
      params: { w: 800, fit: "fill", q: 70 },
    },
  ],
  editorialBackground: [
    {
      mediaQuery: "default",
      params: { w: 900, h: 1160, fit: "fill", q: 70 },
    },
  ],
  editorialContentHero: [
    {
      mediaQuery: "default",
      params: { w: 1050, h: 570, fit: "fill", q: 70 },
    },
  ],
  editorialBackgroundMobile: [
    {
      mediaQuery: "default",
      params: { w: 700, h: 700, fit: "fill", q: 70 },
    },
  ],
  card: [
    {
      mediaQuery: "default",
      params: { w: 900 },
    },
  ],
  callout: [
    {
      mediaQuery: "default",
      params: { w: 900, fit: "fill" },
    },
  ],
  singleCard: [
    {
      mediaQuery: "default",
      params: { w: 900, h: 470, fit: "fill", q: 70 },
    },
  ],
  CarouselWithDescCard: [
    {
      mediaQuery: "default",
      params: { w: 900, h: 900 },
    },
  ],
  classIcon: [
    {
      mediaQuery: "default",
      params: { fit: "thumb", w: 160, h: 160, r: 160 },
    },
  ],
  locationCard: [
    {
      mediaQuery: "default",
      params: { w: 440 },
    },
  ],
  sportsClubCommunity: [
    {
      mediaQuery: "default",
      params: { w: 850 },
      q: 45,
    },
  ],
  singleImageCarouselMobile: [
    {
      fit: "crop",
      f: "top_right",
      params: { w: 640, h: 360 },
      q: 45,
    },
  ],
  socialIcon: [
    {
      mediaQuery: "default",
      params: { w: 40, h: 40 },
    },
  ],
  heroSplitScreen: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
      q: 45,
    },
  ],
  carouselWithVideoAndBackgroundMobile: [
    {
      mediaQuery: "default",
      params: { w: 306 },
      q: 75,
    },
  ],
  carouselWithVideoAndBackgroundTablet: [
    {
      fit: "crop",
      f: "top_right",
      params: { w: 550, h: 340 },
      q: 75,
    },
  ],
  carouselWithVideoAndBackgroundDesktop: [
    {
      params: { w: 660, h: 340 },
      q: 75,
    },
  ],
  carouselWithVideoAndBackground_DesktopBackground: [
    {
      mediaQuery: "default",
      params: { w: 1950, fit: "fill", q: 70 },
    },
  ],
  carouselWithVideoAndBackground_MobileBackground: [
    {
      mediaQuery: "default",
      params: { w: 700, fit: "fill", q: 70 },
    },
  ],
  groupFitnessInstanceImage: [
    {
      params: { w: 600, h: 800, fit: "fill", q: 70 },
    },
  ],
  brandHomePageImage: [
    {
      params: { w: 305, h: 228, q: 75 },
    },
  ],
};
