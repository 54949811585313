import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import JoinLeadApiClient, {
  DEFAULT_JOIN_OUTREACHCODE,
  JOIN_OUTREACHCODE_SUFFIX,
} from "@/api/client/entities/join-lead";
import Selected from "@/assets/icons/radio-selected.svg";
import Unselected from "@/assets/icons/radio-unselected.svg";
import ContactConsentNotice from "@/components/ContactConsentNotice";
import { ACDL_JOIN_FLOW_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/join-flow";
import SpinnerWithOverlay from "@/components/spinner-with-overlay";
import { DigitalDataLayerContext } from "@/context/DigitalDataLayer";
import { UserContext } from "@/context/User";
import { EqxCheckBox, EqxForm, EqxTextInput } from "@/features/eqx-form";
import { utils as userFlowUtils } from "@/features/user-flow";
import FormattingUtils from "@/utils/helpers/formatting";
import ScreenDimensions from "@/utils/helpers/screen_dimensions";
import { ContactInfoFlowSchema } from "@/utils/helpers/validations";

import styles from "./PersonalInfo.module.scss";

const PersonalInfo = ({
  country,
  facilityId,
  defaultUserInfo,
  handleJoinSubmit,
  joinOutreachCode,
  outreachCode,
}) => {
  const [formState, setFormState] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isNewsletter, setIsNewsletter] = useState(false);

  const userContext = useContext(UserContext);
  const { setNewDataLayer } = useContext(DigitalDataLayerContext);

  /* Show Gdpr message if user IP address in EU or club selected is in UK */
  const showGdpr =
    userContext?.userLocation?.location?.is_eu || country === "UK";

  const checkFacilityId = (facilityId, isLeadFlow) => {
    if (isLeadFlow) {
      return !facilityId;
    }
  };

  const onCheck = () => {
    setIsNewsletter((prev) => !prev);
  };

  const createAbandonedJoinLead = async (formData) => {
    const apiResponse = await JoinLeadApiClient.createAbandonedLead({
      ...formData.values,
      facilityId,
      id: null,
      joinOutreachCode,
      outreachCode,
    });

    const hashedEmail = FormattingUtils.encode(formState.email);

    setNewDataLayer(
      ACDL_JOIN_FLOW_TRACKING.joinFlowLeadSubmit(
        hashedEmail,
        apiResponse.TransactionId,
        userFlowUtils.modifyOutreachCodeForVwoCampaign(
          joinOutreachCode ||
            outreachCode + JOIN_OUTREACHCODE_SUFFIX ||
            DEFAULT_JOIN_OUTREACHCODE
        )
      )
    );
  };

  const handleFormValidityChange = (isValid) => {
    setIsFormValid(isValid);
  };

  const handleFormSubmit = async (formValues) => {
    if (isFormValid) {
      const newState = {
        values: {
          firstName: formValues["First Name"],
          lastName: formValues["Last Name"],
          email: formValues["Email Address"],
          phone: formValues["Phone Number"],
          newsletter: showGdpr ? isNewsletter : true,
        },
      };
      setFormState(newState);
      setShowSpinner(true);
      await createAbandonedJoinLead(newState);
      setIsFormSubmitted(true);
    }
  };

  useEffect(() => {
    if (isFormSubmitted) {
      handleJoinSubmit(
        {
          formState,
        },
        {
          setShowSpinner,
          setErrorMessage,
        }
      );

      setIsFormSubmitted(false);
    }

    window.scrollTo({ top: 0 });
  }, [isFormSubmitted, handleJoinSubmit, country]); // eslint-disable-line react-hooks/exhaustive-deps -- WEB-2321

  const disableButtons =
    !isFormValid || checkFacilityId(facilityId) || isFormSubmitted;

  return (
    <>
      <EqxForm
        className={styles.personalInfo}
        defaultValues={{
          "First Name": defaultUserInfo.firstName,
          "Last Name": defaultUserInfo.lastName,
          "Email Address": defaultUserInfo.email,
          "Phone Number": defaultUserInfo.phoneNumber,
        }}
        onSubmit={handleFormSubmit}
        onValidityChange={handleFormValidityChange}
        schema={ContactInfoFlowSchema({ optionalCompanyEmail: false })}
      >
        <EqxTextInput
          className={classNames(styles.formField, styles.fieldFirstName)}
          label="First Name"
        />

        <EqxTextInput
          className={classNames(styles.formField, styles.fieldLastName)}
          label="Last Name"
        />

        <EqxTextInput
          className={styles.formField}
          label="Email Address"
          type="email"
        />

        <EqxTextInput
          className={styles.formField}
          inputProps={{ maxLength: 16 }}
          label="Phone Number"
        />

        <div
          style={{
            display: showGdpr ? "inherit" : "none",
          }}
        >
          <EqxCheckBox
            checked={isNewsletter}
            checkedIcon={<Selected />}
            icon={<Unselected />}
            label="Want to receive exclusive invites, promotions, and news?"
            labelClassName={styles.newsletter}
            labelPlacement="end"
            onCheck={onCheck}
          />
          <p className={styles.newsletterCopy}>
            We’d like to send you exclusive invites, promotions and the latest
            news from Equinox Fitness Holdings UK Limited. We will only send you
            marketing materials we think will be of interest to you and you can
            unsubscribe any time. If you want to receive marketing offers and
            materials from us, please tick the box.** Learn more about our
            practices, and your rights and choices, in our{" "}
            <a href="/privacy" rel="noopener noreferrer" target="_blank">
              Privacy Policy
            </a>
            .
          </p>
        </div>

        {errorMessage && (
          <span
            className={`${styles.errorMessageStyle} ${
              showGdpr && styles.errorMessageStyleGdpr
            }`}
          >
            {errorMessage}
          </span>
        )}

        <ContactConsentNotice className={styles.agree} />

        <button
          data-adobe="joinSubmitStep2"
          disabled={disableButtons}
          name="JoinFlow:PersonalInfo - Next"
          type="submit"
        >
          Next
        </button>

        {showGdpr && (
          <small className={styles.legal}>
            **Please note that, even if you elect not to receive marketing
            materials, by completing and submitting this form you are requesting
            that an Equinox Membership Advisor contact you regarding membership
            and are agreeing that a Membership Advisor may contact you for such
            purpose, including by providing with you with information on any
            current new member specials.
          </small>
        )}
      </EqxForm>
      {showSpinner && <SpinnerWithOverlay />}
    </>
  );
};

PersonalInfo.propTypes = {
  country: PropTypes.string,
  defaultUserInfo: PropTypes.object,
  facilityId: PropTypes.string,
  handleJoinSubmit(props, propName, componentName) {
    if (typeof props[propName] !== "function") {
      return new Error(
        `Prop handleJoinSubmit of type function is required if isJoinFlow is true in Component ${componentName}.`
      );
    }
  },
  joinOutreachCode: PropTypes.string,
  outreachCode: PropTypes.string,
};

export default ScreenDimensions(PersonalInfo);
